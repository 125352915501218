import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import RichText, { RichTextType } from './Richtext';
const { list: colors } = config.colors;

export type DisclaimerType = RichTextType & {
  richtext: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  fontSize?: string;
  color?: string;
  padding?: string;
  margin?: string;
};

type DisclaimerProps = {
  fontSize?: string;
  color?: string;
  padding?: string;
  margin?: string;
};

const DisclaimerBlock = styled.div<DisclaimerProps>`
  padding: ${({ padding }) => padding || '3rem 0'};
  margin: ${({ margin }) => margin || '0'};
  color: ${({ color }) => colors[color] || colors.BlueMoon};
  line-height: 1.5;
  &,
  a,
  p,
  span {
    font-size: ${({ fontSize }) => fontSize || '1.4rem'};
  }
`;

type Props = DisclaimerType;

const Disclaimer: React.FC<Props> = ({
  richtext,
  fontSize,
  color,
  padding,
  margin,
}) => {
  return (
    <DisclaimerBlock
      fontSize={fontSize}
      color={color}
      padding={padding}
      margin={margin}
    >
      {richtext && <RichText data={richtext} options={{ textSize: null }} />}
    </DisclaimerBlock>
  );
};

export default Disclaimer;

export const disclaimerQuery = graphql`
  fragment DisclaimerFragment on ContentfulDisclaimer {
    richtext {
      ...RichTextFragment
    }
  }
`;
