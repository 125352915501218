import React from 'react';
import { graphql } from 'gatsby';

import Page, { PageData, PageInfoType, PageType } from '@/components/Page';
import SupportArticle, {
  SupportArticleType,
} from '@/components/support/SupportArticle';
import { addPrefixToLanguageSlugs } from '@/utils/SlugUtils';

type SupportArticleTypeData = SupportArticleType & {
  faq_item: Array<FaqItem>;
};
type FaqItem = {
  faq_subtheme: Array<FaqSubTheme>;
  faq_main_theme: Array<FaqMainTheme>;
};
type FaqSubTheme = {
  faq_main_theme: Array<FaqMainTheme>;
};
type FaqMainTheme = {
  title: string;
  support_theme_page_type: Array<SupportThemePage>;
};
type SupportThemePage = {
  page: PageInfoType;
};
type SupportArticlePageData = PageData & {
  pageType: SupportArticleTypeData;
};
type Props = PageType & {
  data: {
    contentfulPage: SupportArticlePageData;
    allSupportPages: {
      nodes: Array<{ page: Array<PageInfoType> }>;
    };
  };
  pageContext: {
    supportPath: string;
  };
  location?: URL;
};

const SupportArticlePage: React.FC<Props> = (props) => {
  const page = props.data.contentfulPage;
  const article: SupportArticleType = page.pageType;
  article.locale = page.node_locale;
  article.supportPath = props.pageContext.supportPath;
  article.location = props.location;
  const supportArticle = page.pageType;
  if (supportArticle) {
    //first check in subThemes
    const subTheme = supportArticle.faq_subtheme
      ? supportArticle.faq_subtheme.find(
          (theme) => theme.faq_main_theme !== null,
        )
      : null;
    if (subTheme) {
      const mainTheme = subTheme.faq_main_theme
        ? subTheme.faq_main_theme[0]
        : null;
      if (mainTheme) {
        article.mainThemeTitle = mainTheme.title;
        article.mainThemePage = mainTheme.support_theme_page_type
          ? mainTheme.support_theme_page_type[0].page
            ? mainTheme.support_theme_page_type[0].page[0]
            : null
          : null;
      }
    } else {
      // else check in mainThemes (topQuestions)
      // maybe not needed? topQuestion should always be question in subtheme?
      const mainTheme = supportArticle.faq_main_theme
        ? supportArticle.faq_main_theme[0]
        : null;
      if (mainTheme) {
        article.mainThemeTitle = mainTheme.title;
        article.mainThemePage = mainTheme.support_theme_page_type
          ? mainTheme.support_theme_page_type[0].page
            ? mainTheme.support_theme_page_type[0].page[0]
            : null
          : null;
      }
    }
  }
  if (page.parentPage) {
    article.path = props.path;
    article.parentPage = page.parentPage;
  }
  addPrefixToLanguageSlugs(
    props.data.allLanguages.nodes,
    props.data.allSupportPages.nodes.map((node) => node.page[0]),
  );
  return (
    <Page {...props}>
      <SupportArticle {...article} />
    </Page>
  );
};

export default SupportArticlePage;

export const pageQuery = graphql`
  query SupportArticleQuery($contentfulId: String!, $locale: String) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      node_locale
      ...PagePathFragment

      seoMetadata {
        ...SeoMetaDataFragment
      }
      pageType {
        ... on ContentfulSupportArticle {
          ...SupportArticleFragment
        }
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    contentfulSupportHomepageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
    allSupportPages: allContentfulSupportHomepageType {
      nodes {
        page {
          node_locale
          ...PagePathFragment
        }
      }
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
  }
`;
