import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Link } from '@keytrade/components-link';
import { Icon } from '@keytrade/components-icon';
import { config } from '@keytrade/functions';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';

import Container from '@/components/Container';
import Richtext, { RichTextType } from '@/components/Richtext';
import Disclaimer, { DisclaimerType } from '@/components/Disclaimer';
import { getBackgroundColor } from '@/utils/StyleUtils';
import { PageInfoType } from '@/components/Page';
import Title from '@/components/Title';
import { generateSlug } from '@/utils/SlugUtils';
import CurrencyFormatter from '@/formatters/CurrencyFormatter';
import SupportSearch from '@/components/search/SupportSearch';
import BlockWrapper from '@/components/BlockWrapper';
import useTranslations from '@/hooks/useTranslations';

const { list: colors } = config.colors;

const Wrapper = styled.div``;

const FooterContent = styled.footer`
  display: flex;
  background-color: ${getBackgroundColor('Light blue')};
  padding: 0 0 7.2rem 0;
`;

const ArticleContainer = styled.div`
  padding-bottom: 11.2rem;
`;

const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1.8rem;
  flex-wrap: wrap;
`;

export type SupportArticleType = RichTextType & {
  title: string;
  disclaimer?: DisclaimerType;
  locale: string;
  path: string;
  parentPage?: PageInfoType;
  supportPath?: string;
  mainThemeTitle?: string;
  mainThemePage?: PageInfoType;
  location?: URL & {
    state?: {
      searchValue?: string;
    };
  };
  data: {
    contentfulPage: PageInfoType;
  };
};

type Props = SupportArticleType;

const SupportArticle: React.FC<Props> = (props) => {
  const {
    title,
    richText,
    defaultPaths,
    disclaimer,
    supportPath,
    mainThemeTitle,
    mainThemePage,
    location,
  } = props;
  const [isSearchingSupport, setIsSearchingSupport] = useState(false);
  const { FormattedMessage } = useTranslations();

  return (
    <Wrapper>
      <SupportSearch
        {...props}
        setParentSearchingSupport={setIsSearchingSupport}
        location={location}
      >
        {!isSearchingSupport && (
          <BlockWrapper padding='xsmall'>
            <ArticleContainer>
              <BreadCrumb>
                <Link
                  render={() => (
                    <GatsbyLink to={supportPath}>
                      <CurrencyFormatter>
                        <FormattedMessage id='keytrade_support' />
                      </CurrencyFormatter>
                    </GatsbyLink>
                  )}
                  isSemiBold
                  margin='0 1.3rem 0.2rem 0'
                />
                {mainThemePage && (
                  <>
                    <Icon
                      icon='icn_arrowRight'
                      size='12'
                      margin='0 1.3rem 0.2rem 0'
                      color={colors.GreyWarm}
                    />
                    <Link
                      isSemiBold
                      margin='0 1.3rem 0.2rem 0'
                      render={() => (
                        <GatsbyLink to={generateSlug(mainThemePage)}>
                          <CurrencyFormatter>
                            {mainThemeTitle}
                          </CurrencyFormatter>
                        </GatsbyLink>
                      )}
                    />
                  </>
                )}
              </BreadCrumb>
              <Title size='xl' level='h1' margin='3.2rem 0'>
                {title}
              </Title>
              <Richtext
                data={richText}
                defaultPaths={defaultPaths}
                options={{ textSize: 'lg', noCheckMarkListIcons: true }}
              />
            </ArticleContainer>
          </BlockWrapper>
        )}
      </SupportSearch>
      {!isSearchingSupport && disclaimer && (
        <FooterContent>
          <Container narrow={true}>
            {disclaimer && <Disclaimer {...disclaimer} />}
          </Container>
        </FooterContent>
      )}
    </Wrapper>
  );
};

export default SupportArticle;

export const articleQuery = graphql`
  fragment SupportArticleFragment on ContentfulSupportArticle {
    title
    faq_subtheme {
      faq_main_theme {
        title
        support_theme_page_type {
          page {
            ...PagePathFragment
          }
        }
      }
    }
    faq_main_theme {
      title
      support_theme_page_type {
        page {
          ...PagePathFragment
        }
      }
    }

    richText {
      ...RichTextFragment
    }
    disclaimer {
      ...DisclaimerFragment
    }
  }
`;
